// React & Props
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

import LatticeGrid from "lattice/lib/components/LatticeGrid";
// import Modal from "./modal";
import MediaCarousel from "./media-carousel";
import Image from "../atoms/image";
import { getCarouselSettings } from "../../utils";
import CONSTANTS from "../../constants";

const Modal = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-organisms-modal' */ "./modal"
  ),
);

function ModalCarousel({
  sliderBlocks,
  sliderImages,
  mobileSliderImages,
  productColors,
  activeProductColor,
  open,
  handleClose,
  currentSlide,
  headline,
  className,
  hideOnDesktop,
  desktopWidth,
  legacy,
}) {
  const [slideIndex, setSlideIndex] = useState(currentSlide);
  let classList = ["shared__modal--carousel"];
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );

  useEffect(() => {
    setSlideIndex(currentSlide);
  }, [currentSlide]);

  const imageSlides =
    sliderBlocks && sliderBlocks.length
      ? sliderBlocks.map((mediaBlock) => {
          const imageSources =
            mediaBlock.mobileImage && mediaBlock.image
              ? {
                  desktop: mediaBlock.image,
                  mobile: mediaBlock.mobileImage,
                }
              : null;
          return (
            <Image
              image={imageSources || mediaBlock.image}
              alt="Product Detail"
              className="product-modal-slide"
              loading="lazy"
              key={mediaBlock.id}
              legacy={legacy}
            />
          );
        })
      : sliderImages.map((simg, sindex) => {
          // Get color image if there is one
          let sliderBackground =
            mobileSliderImages && mobileSliderImages[sindex] && simg
              ? {
                  desktop: simg,
                  mobile: mobileSliderImages[sindex],
                }
              : {
                  desktop: simg,
                };
          let sliderBackgroundDescription = simg.description;
          if (productColors.length && activeProductColor) {
            if (
              activeProductColor.colorImages &&
              activeProductColor.colorImages.length > sindex
            ) {
              sliderBackground =
                activeProductColor.mobileColorImages &&
                activeProductColor.mobileColorImages[sindex]
                  ? {
                      desktop: activeProductColor.colorImages[sindex],
                      mobile: activeProductColor.mobileColorImages[sindex],
                    }
                  : {
                      desktop: activeProductColor.colorImages[sindex],
                    };
              sliderBackgroundDescription =
                activeProductColor.colorImages[sindex].description;
            } else if (sindex === 0) {
              sliderBackground = activeProductColor.mobileColorImage
                ? {
                    desktop: activeProductColor.colorImage,
                    mobile: activeProductColor.mobileColorImage,
                  }
                : {
                    desktop: activeProductColor.colorImage,
                  };
              sliderBackgroundDescription =
                activeProductColor.colorImage.description;
            }
          }
          return (
            <Image
              image={sliderBackground}
              alt={sliderBackgroundDescription}
              className="product-modal-slide"
              loading="lazy"
              key={simg.contentful_id || simg.id || sindex}
              legacy={legacy}
            />
          );
        });

  const sliderCarousel = (
    <>
      <LatticeGrid className="modal-carousel-info modal-carousel-tracker">
        <p>
          {slideIndex + 1}/
          {(sliderBlocks && sliderBlocks.length) ||
            (sliderImages && sliderImages.length)}
        </p>
      </LatticeGrid>
      <MediaCarousel
        // className={carouselClasses}
        // title={headline}
        {...getCarouselSettings("modal-carousel", {
          currentSlide,
          beforeChange: (oldIndex, newIndex) => {
            setSlideIndex(newIndex);
          },
        })}>
        {imageSlides}
      </MediaCarousel>
      <LatticeGrid className="modal-carousel-info modal-carousel-title">
        <p>{headline}</p>
      </LatticeGrid>
    </>
  );
  return open ? (
    <Modal
      handleClose={() => {
        setSlideIndex(currentSlide);
        handleClose();
      }}
      hideOnDesktop={hideOnDesktop}
      desktopWidth={desktopWidth}
      className={classList.join(" ")}>
      {sliderCarousel}
    </Modal>
  ) : null;
}

ModalCarousel.designSystemProps = {
  sliderBlocks: {
    type: "array",
    description: "List of slider blocks that contain images",
    propType: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      }),
    ),
    default: [],
    required: false,
  },
  sliderImages: {
    type: "array",
    description: "List of slider images",
    propType: PropTypes.arrayOf(PropTypes.any),
    default: [],
    required: false,
  },
  mobileSliderImages: {
    type: "array",
    description: "List of mobile slider images",
    propType: PropTypes.arrayOf(PropTypes.any),
    default: [],
    required: false,
  },
  productColors: {
    type: "array",
    description: "List of product color info",
    propType: PropTypes.arrayOf(PropTypes.any),
    default: [],
    required: false,
  },
  activeProductColor: {
    type: "object",
    description: "The active product color",
    propType: PropTypes.objectOf(PropTypes.any),
    default: null,
    required: false,
  },
  open: {
    type: "boolean",
    description: "Displays modal if true.",
    required: false,
    default: false,
  },
  handleClose: {
    type: "function",
    description: "Event handler when modal is closed.",
    required: false,
    default: null,
    propType: PropTypes.func,
  },
  currentSlide: {
    type: "number",
    description: "Index of active slide",
    propType: PropTypes.number,
    default: 0,
    required: false,
  },
  headline: {
    type: "string",
    description: "Modal carousel headline",
    propType: PropTypes.string,
    default: "",
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  hideOnDesktop: {
    type: "boolean",
    description: "Will hide modal on desktop devices and up if true",
    propType: PropTypes.bool,
    required: false,
    default: false,
  },
  desktopWidth: {
    type: "number",
    description: "Width in pixels of minimum desktop breakpoint",
    propType: PropTypes.number,
    default: null,
    required: false,
  },
  legacy: {
    type: "boolean",
    description: "Use legacy image plugin",
    default: CONSTANTS.USE_LEGACY_IMAGE_PLUGIN,
    propType: PropTypes.bool,
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(ModalCarousel.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
ModalCarousel.propTypes = { ...propTypes };
ModalCarousel.defaultProps = { ...defaultProps };

ModalCarousel.displayName = "ModalCarousel";

export default ModalCarousel;
